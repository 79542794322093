import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from '@styles/ActivityAddForm.module.css';
import styles_input from '@styles/InputContainer.module.css';
import { MdCalendarToday, MdKeyboardArrowDown } from 'react-icons/md';
import Floated from '@components/Floated';
import PassengerPicker from '@components/PassengerPicker';
import dayjs from 'dayjs';
import SpinnerButton from '../SpinnerButton';
import ActivityPricing from '@components/activity/ActivityPricing';
import { Selector } from '../../utils/constans';
import axios from 'axios';
import * as config from '@config';
import CommissionPrint from '../CommissionPrint';
import { GetToken } from '../../utils/storage';

const defaultBookingData = {
    date: '',
    numAdult: 0,
    numChild: 0,
    bundleQty: 0,
    numFamily: 0,
    segmentID: '',
    variantID: '',
};

export const DefaultPricing = {
    hasFamilyPlan: false,
    familyPrice: '',
    familyAdultNum: '',
    familyChildNum: '',
    adultPrice: '',
    childPrice: '',
    bundlePrice: '',
    bundlePriced: false,
};

export const ActivityDefaultBaseData = {
    name: '',
    nameEN: '',
    tourCode: '',
    subtitle: '',
    pricing: { ...DefaultPricing },
    content: '', // HTML content
    selectors: [],
    hidden: true,
    demo: false,
    variants: [],
    pickupIncluded: false,
    pickupAbility: false,
    pickupFeePerPerson: '',
    segmented: false,
    segmentOptions: [],
    information: [],
};

function ActivityAddForm({
    activity = { ActivityDefaultBaseData },
    commission = {},
    showDatePicker = false,
    showQtyPicker = true,
    handleSubmit = (e) => console.log(e),
    loading = false,
}) {
    const [fetching, setFetching] = useState(false);
    const [effectPricing, setEffectPricing] = useState(DefaultPricing);
    const [booking, setBooking] = useState(defaultBookingData);
    const [passengerPickerActive, setPassengerPickerActive] = useState(false);
    const [error, setError] = useState(false);
    const [isDaily, setIsDaily] = useState(false);

    const controller = new AbortController();

    useEffect(() => {
        // console.log(activity);
        return () => {
            setPassengerPickerActive(false);
        };
    }, []);

    useEffect(() => {
        if (activity.selectors?.includes(Selector.Cars)) {
            setIsDaily(true);
        }
        if (activity) {
            setEffectPricing(activity.pricing);
        }
    }, [activity]);

    useEffect(() => {
        if (activity.id) {
            fetchEffectPricing();
        }
        return () => {
            if (fetching) controller.abort();
        };
    }, [booking?.segmentID, booking?.variantID]);

    const fetchEffectPricing = () => {
        setFetching(true);
        const url = new URL(`${config.apiURL}/activity/effect-pricing`);
        const req = {
            activityID: activity.id,
            segmentID: booking.segmentID,
            variantID: booking.variantID,
        };
        axios
            .post(url, req, {
                signal: controller.signal,
                headers: { Authorization: `Bearer ${GetToken()}` },
            })
            .then((res) => {
                const effectPricing = res.data.effectPricing;
                if (effectPricing) {
                    setEffectPricing(effectPricing);
                }
            })
            .catch((err) => {
                let msg = err.toString();
                if (err.response?.data?.error) {
                    console.log('***', err.response.data.error);
                    msg = err.response.data.error;
                }
                console.error(msg);
            })
            .finally(() => {
                setFetching(false);
            });
    };

    const handleClickSegment = (segmentID) => {
        setBooking((prev) => {
            return { ...prev, segmentID: segmentID };
        });
    };

    const handleClickVariant = (variantID) => {
        setBooking((prev) => {
            return { ...prev, variantID: variantID };
        });
    };

    const handleClickSubmit = () => {
        if (showDatePicker && !booking.date) {
            setError(true);
            window.alert('请选择日期');
            return;
        }
        if (fetching) {
            console.warn('Still fetching pricing');
            return;
        }
        handleSubmit(booking);
    };

    return (
        <div className={styles.container}>
            <div className={styles.leading_container}>
                <div className={styles.grouped_container}>
                    {showDatePicker && (
                        <div className={styles.date_picker_container}>
                            <div className={`${styles_input.input_container} ${error && styles_input.input_error}`}>
                                <MdCalendarToday className={styles_input.icon} />
                                <input
                                    className={styles_input.input}
                                    name="fromDate"
                                    placeholder="dd-mm-yyyy"
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setBooking((prev) => {
                                            return { ...prev, date: value };
                                        });
                                        setError(false);
                                    }}
                                    type="date"
                                    min={dayjs().add(1, 'day').format('YYYY-MM-DD')}
                                    value={booking.date ?? ''}
                                />
                                <label>
                                    <span className={styles_input.label_content}>日期</span>
                                </label>
                            </div>
                        </div>
                    )}
                    {!!showQtyPicker && (
                        <div className={styles.passenger_container}>
                            {effectPricing?.bundlePriced == false && (
                                <>
                                    <span
                                        className={styles.text}
                                        onClick={() => setPassengerPickerActive((prev) => !prev)}
                                    >
                                        {booking.numAdult + booking.numChild} 张票 ({booking.numChild}儿童)
                                        <br />
                                        {!!booking.numFamily && <>{booking.numFamily} 家庭票</>}
                                    </span>
                                    <MdKeyboardArrowDown
                                        className={styles.icon}
                                        onClick={() => setPassengerPickerActive((prev) => !prev)}
                                    />
                                    <Floated
                                        active={passengerPickerActive}
                                        setActive={setPassengerPickerActive}
                                        style={{ right: '-75%', top: '40px', height: 'auto' }}
                                    >
                                        <PassengerPicker
                                            callback={() => setPassengerPickerActive(false)}
                                            numAdult={booking.numAdult}
                                            setNumAdult={(value) =>
                                                setBooking((prev) => {
                                                    return { ...prev, numAdult: value };
                                                })
                                            }
                                            numChild={booking.numChild}
                                            setNumChild={(value) =>
                                                setBooking((prev) => {
                                                    return { ...prev, numChild: value };
                                                })
                                            }
                                            hasFamilyPlan={effectPricing.hasFamilyPlan}
                                            numFamily={booking.numFamily}
                                            setNumFamily={(value) =>
                                                setBooking((prev) => {
                                                    return { ...prev, numFamily: value };
                                                })
                                            }
                                        />
                                    </Floated>
                                </>
                            )}
                            {effectPricing?.bundlePriced == true && (
                                <>
                                    数量
                                    <select
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setBooking((prev) => {
                                                return { ...prev, bundleQty: parseInt(value) };
                                            });
                                        }}
                                        value={booking.bundleQty}
                                    >
                                        <option value={0}>0 {isDaily && '天'}</option>
                                        <option value={1}>1 {isDaily && '天'}</option>
                                        <option value={2}>2 {isDaily && '天'}</option>
                                        <option value={3}>3 {isDaily && '天'}</option>
                                        <option value={4}>4 {isDaily && '天'}</option>
                                        <option value={5}>5 {isDaily && '天'}</option>
                                        <option value={6}>6 {isDaily && '天'}</option>
                                        <option value={7}>7 {isDaily && '天'}</option>
                                        <option value={8}>8 {isDaily && '天'}</option>
                                        <option value={9}>9 {isDaily && '天'}</option>
                                        <option value={10}>10 {isDaily && '天'}</option>
                                        <option value={11}>11 {isDaily && '天'}</option>
                                        <option value={12}>12 {isDaily && '天'}</option>
                                        <option value={13}>13 {isDaily && '天'}</option>
                                    </select>
                                </>
                            )}
                        </div>
                    )}
                </div>
                <div className={styles.action_container}>
                    <SpinnerButton onClick={handleClickSubmit} loading={loading}>
                        加入行程
                    </SpinnerButton>
                </div>
            </div>
            <div className={styles.trailing_container}>
                {activity.segmented && (
                    <div className={styles.segments_container}>
                        {activity?.segmentOptions?.map((item, index) => {
                            return (
                                <div
                                    className={styles.segment_container}
                                    aria-selected={booking.segmentID == item.id && 'true'}
                                    key={index}
                                    onClick={() => handleClickSegment(item.id)}
                                >
                                    <b>{item.value}</b>
                                </div>
                            );
                        })}
                    </div>
                )}

                <div className={styles.pricing_and_code_container}>
                    <div className={styles.pricing_container}>
                        <ActivityPricing pricing={effectPricing} showFlamilyPersonNum={true} />
                    </div>
                    <div className={styles.trailing_container}>
                        {!!activity.tourCode && (
                            <div className={styles.tour_code_container}>Code: {activity.tourCode}</div>
                        )}
                        <div className={styles.commission}>
                            <CommissionPrint commission={commission} />
                        </div>
                    </div>
                </div>
                {activity?.variants?.length > 0 && (
                    <div className={styles.variants_container}>
                        {activity?.variants?.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    aria-selected={booking.variantID == item.id && 'true'}
                                    className={styles.variant_container}
                                    onClick={() => handleClickVariant(item.id)}
                                >
                                    <div className={styles.left_container}>
                                        <b>{item.nameZH}</b>
                                        <p>{item.description}</p>
                                    </div>
                                    <div className={styles.right_conatiner}>
                                        {item.overridePricing && (
                                            <div className={styles.pricing_container}>
                                                <ActivityPricing pricing={item.pricing} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
                <div className={styles.pickup_container}>
                    {activity.pickupIncluded && <p>*包含接驳（Pickup Included）</p>}
                    {activity.pickupAvailable && <p>*接驳每人 A${activity.pickupFeePerPerson}</p>}
                </div>
            </div>
        </div>
    );
}

ActivityAddForm.propTypes = {
    activity: PropTypes.shape({
        pricing: PropTypes.shape({
            hasFamilyPlan: PropTypes.bool,
            familyAdultNum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            familyChildNum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            familyPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            adultPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            childPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            bundlePrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            bundlePriced: PropTypes.bool,
        }),
        pickupIncluded: PropTypes.bool,
        pickupAbility: PropTypes.bool,
        pickupFeePerPerson: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        segmented: PropTypes.bool,
        segmentOptions: PropTypes.array,
    }).isRequired,
    commission: PropTypes.object.isRequired,
    showDatePicker: PropTypes.bool,
    showQtyPicker: PropTypes.bool,
    loading: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
};

export default ActivityAddForm;
