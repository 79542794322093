import React, { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import * as config from '../config';
import { Loader } from 'semantic-ui-react';
import { FaFilePdf } from 'react-icons/fa';
import { OpenURLFile } from '../utils/utils';
import styles from '@styles/VisaPage.module.css';
import { useParams, useSearchParams } from 'react-router-dom';
import { Visa } from '../utils/constans';
import { GetToken } from '../utils/storage';

function VisaPage() {
    const [fetching, setFetching] = useState(false);
    const [data, setData] = useState({});
    const { cat } = useParams();

    const controller = new AbortController();

    useEffect(() => {
        fetchData();
        return () => {
            if (fetching) controller.abort();
        };
    }, [cat]);

    const fetchData = () => {
        setFetching(true);
        axios
            .get(`${config.apiURL}/visa?cat=${cat ?? Visa.Tourist}`, {
                signal: controller.signal,
                headers: { Authorization: `Bearer ${GetToken()}` },
            })
            .then((res) => {
                const data = res.data;
                setData(data);
            })
            .catch((err) => {
                let msg = err.toString();
                if (err.response?.data?.error) {
                    console.log('***', err.response.data.error);
                    msg = err.response.data.error;
                }
                console.error(msg);
            })
            .finally(() => {
                setFetching(false);
            });
    };

    return (
        <div className={styles.container}>
            <Loader active={fetching} inline>
                Loading
            </Loader>
            <h3>{data.name}</h3>
            <div className={`workaround_description`} dangerouslySetInnerHTML={{ __html: data.content }} />
            <div className={styles.documents_container}>
                {data?.filenames?.map((item, index) => {
                    return (
                        <div key={index} className={styles.document_container}>
                            <FaFilePdf className={styles.icon} />
                            <div className={styles.filename} onClick={() => OpenURLFile(item)} title={item}>
                                {item}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default VisaPage;
