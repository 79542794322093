import React from 'react';
import PropTypes from 'prop-types';
import styles from '@styles/SpinnerButton.module.css';
import SpinnerInline from '@components/SpinnerInline';

function SpinnerButton({ loading = false, children, ...rest }) {
    return (
        <button className={loading ? `primary_btn ${styles.spinner_btn}` : 'primary_btn'} {...rest}>
            {children}
            <div className={styles.spinner_container}>
                <SpinnerInline active={loading} />
            </div>
        </button>
    );
}

SpinnerButton.propTypes = {
    loading: PropTypes.bool,
};

export default SpinnerButton;
