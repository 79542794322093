import React from 'react';

function SupportPage() {
    return (
        <div>
            <h3>客服</h3>
            <div>
                客服邮箱: <code>support@aotravelconnect.com</code>
            </div>
            <br />
        </div>
    );
}

export default SupportPage;
