import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as config from '@config';
import { Header, Loader, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { CAT } from '@utils/constans';
import { GetToken } from '../utils/storage';

function FAQsPage(props) {
    const [fetching, setFetching] = useState(false);
    const [data, setData] = useState([]);

    const controller = new AbortController();

    useEffect(() => {
        fetchData();
        return () => {
            if (fetching) controller.abort();
        };
    }, []);

    const fetchData = async () => {
        setFetching(true);
        try {
            const res = await axios.get(`${config.adminApiURL}/articles?cat=${CAT.FAQ4Agency}`, {
                signal: controller.signal,
                headers: { Authorization: `Bearer ${GetToken()}` },
            });
            if (res.data.error) {
                window.alert(res.data.error);
            } else if (res.data.articles) {
                setData(res.data.articles);
            }
        } catch (error) {
            const errMsg = error.response?.data?.error;
            if (!!errMsg) {
                window.alert(errMsg);
            } else {
                window.alert(error);
            }
        }
        setFetching(false);
    };

    return (
        <div>
            <Loader active={fetching} inline="centered">
                Loading
            </Loader>
            <h3>FAQs</h3>
            {data.map((item, index) => {
                let dom = new DOMParser().parseFromString(item.content, 'text/html');
                let txt = dom.body.textContent;
                txt = txt.slice(0, 68);
                return (
                    <Segment key={index}>
                        <Header as="h4">
                            {item.title}
                            <Header.Subheader>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'baseline',
                                        marginTop: '8px',
                                    }}
                                >
                                    <span>{item.subtitle}</span>
                                    <span>{dayjs(item.updatedAt).format('YYYY-MM-DD')} 更新</span>
                                </div>
                            </Header.Subheader>
                        </Header>
                        {/* <p>{txt}......</p> */}
                        <Link to={item.id}>
                            <button className="secondary_btn">了解更多</button>
                        </Link>
                    </Segment>
                );
            })}
        </div>
    );
}

FAQsPage.propTypes = {};

export default FAQsPage;
