import React from 'react';
import styles from '@styles/Incentive.module.css';

function Incentive() {
    return (
        <div className={styles.container}>
            <p>等级资质</p>
            <p>达到以下旅行产品的年度总预定价值目标，以提高您的佣金率：</p>
            <p>旅行产品包括：活动体验、 酒店、 机票 （ 澳大利亚，新西兰境跨海，或境内）</p>
            <p>
                一旦您达到新的等级（白银50,000 澳元、黄金200,000 澳元或钻石550,000 澳元年度
                ），您将按照新的更高费率赚取收入，直到下一个日历年年底。
            </p>
            <p>
                支付佣金付款方式 一旦达到 100
                澳元的最低付款金额，付款将按月发送。低于100澳币的将会在下一个月完成预定的佣金一起支付。
            </p>
            <table>
                <thead>
                    <tr>
                        <th>旅游产品</th>
                        <th>普通</th>
                        <th>白银</th>
                        <th>黄金</th>
                        <th>钻石</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>澳洲机票</td>
                        <td>0%</td>
                        <td>2%</td>
                        <td>4%</td>
                        <td>5%</td>
                    </tr>
                    <tr>
                        <td>澳洲酒店</td>
                        <td>0%</td>
                        <td>5%</td>
                        <td>7%</td>
                        <td>9%</td>
                    </tr>
                    <tr>
                        <td>景点门票</td>
                        <td>10%</td>
                        <td>12%</td>
                        <td>15%</td>
                        <td>18%</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default Incentive;
